:root {
  --animation-load-delay: 5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slide-t {
  from {
    transform: translate(0, 2rem);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-b {
  from {
    transform: translate(0, -2rem);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-l {
  from {
    transform: translate(2rem, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-r {
  from {
    transform: translate(-2rem, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-s-t {
  from {
    transform: translate(0, 1rem);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-s-b {
  from {
    transform: translate(0, -1rem);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-s-l {
  from {
    transform: translate(1rem, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-s-r {
  from {
    transform: translate(-1rem, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes fly-in-t {
  from {
    transform: translate(0, -100vh);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes fly-in-b {
  from {
    transform: translate(0, 100vh);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes fly-in-l {
  from {
    transform: translate(-100vw, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes fly-in-r {
  from {
    transform: translate(100vw, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes map-highlight {
  from {
    fill: var(--map-fill);
    stroke: var(--map-stroke);
    transform: scale(1);
  }
  to {
    fill: var(--map-highlighted-fill);
    stroke: var(--map-highlighted-stroke);
    transform: scale(1.04);
  }
}
@layer utilities {
  .animate-on-load {
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-delay: var(--animation-load-delay, 0s);
  }
  .animate-on-scroll {
    --scroll-animation-delay: calc(
      0.05s + (var(--animation-stagger, 0) * 0.18s) +
        var(--animation-load-delay, 0s)
    );
    animation-fill-mode: both;
    animation-duration: 0.8s;
    animation-delay: var(--scroll-animation-delay);
  }
  .fade-in {
    animation-name: fade-in;
  }
  .fade-in-l {
    animation-name: fade-in, slide-s-l;
  }
  .fade-in-r {
    animation-name: fade-in, slide-s-r;
  }
  .fade-in-t {
    animation-name: fade-in, slide-s-t;
  }
}
body,
.parallax__page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body > *,
.parallax__page > * {
  flex: none;
}

body,
.theme-light, .theme-dark, .theme-brand, .theme-dark-blue {
  color: var(--theme-tx);
  background-color: var(--theme-bg);
  border-color: var(--theme-tx);
}

:root {
  --panel-tiles: white;
}

.theme-dark-blue, .theme-brand, .theme-dark, body,
.theme-light {
  --theme-br--light: hsl(var(--theme-br-h), calc(var(--theme-br-s) + -10%), calc(var(--theme-br-l) + 10%));
  --theme-br--hint: hsla(var(--theme-br-h), var(--theme-br-s), var(--theme-br-l), 0.5);
}

.theme-brand, .theme-dark-blue, .theme-dark {
  --map-fill: var(--theme-br);
  --map-stroke: var(--theme-bg);
  --map-highlighted-fill: var(--theme-br);
  --map-highlighted-stroke: var(--map-stroke);
}

.theme-dark-blue, .theme-dark {
  --map-fill: var(--theme-tx);
}

.theme-brand {
  --map-fill: hsla(var(--theme-tx-h), var(--theme-tx-s), var(--theme-tx-l), 0.5);
  --map-highlighted-fill: var(--theme-tx);
}

.theme-brand {
  --panel-tiles: var(--theme-tx);
}

.theme-dark-blue, .theme-dark, body,
.theme-light {
  --panel-tiles: var(--theme-br);
}

body,
.theme-light {
  --theme-tx: hsla(var(--theme-tx-h), var(--theme-tx-s), var(--theme-tx-l), var(--theme-tx-a));
  --theme-tx-r: 17;
  --theme-tx-g: 17;
  --theme-tx-b: 17;
  --theme-tx-h: 0deg;
  --theme-tx-s: 0%;
  --theme-tx-l: 6.6666666667%;
  --theme-tx-a: 1;
  --theme-bg: hsla(var(--theme-bg-h), var(--theme-bg-s), var(--theme-bg-l), var(--theme-bg-a));
  --theme-bg-r: 255;
  --theme-bg-g: 255;
  --theme-bg-b: 255;
  --theme-bg-h: 0deg;
  --theme-bg-s: 0%;
  --theme-bg-l: 100%;
  --theme-bg-a: 1;
  --button-bg: hsla(var(--button-bg-h), var(--button-bg-s), var(--button-bg-l), var(--button-bg-a));
  --button-bg-r: 9;
  --button-bg-g: 137;
  --button-bg-b: 177;
  --button-bg-h: 194.2857142857deg;
  --button-bg-s: 90.3225806452%;
  --button-bg-l: 36.4705882353%;
  --button-bg-a: 1;
  --button-text: hsla(var(--button-text-h), var(--button-text-s), var(--button-text-l), var(--button-text-a));
  --button-text-r: 255;
  --button-text-g: 255;
  --button-text-b: 255;
  --button-text-h: 0deg;
  --button-text-s: 0%;
  --button-text-l: 100%;
  --button-text-a: 1;
  --theme-br: hsla(var(--theme-br-h), var(--theme-br-s), var(--theme-br-l), var(--theme-br-a));
  --theme-br-r: 9;
  --theme-br-g: 137;
  --theme-br-b: 177;
  --theme-br-h: 194.2857142857deg;
  --theme-br-s: 90.3225806452%;
  --theme-br-l: 36.4705882353%;
  --theme-br-a: 1;
  --theme-light: 1;
  --theme-dark: 0;
  --theme-tx--light: rgba(calc((var(--theme-tx-r) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-r) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-g) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-g) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-b) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-b) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-a) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-a) * (1 - (0.58 + (0.18 * var(--theme-dark)))))));
}

.theme-dark {
  --theme-tx: hsla(var(--theme-tx-h), var(--theme-tx-s), var(--theme-tx-l), var(--theme-tx-a));
  --theme-tx-r: 255;
  --theme-tx-g: 255;
  --theme-tx-b: 255;
  --theme-tx-h: 0deg;
  --theme-tx-s: 0%;
  --theme-tx-l: 100%;
  --theme-tx-a: 1;
  --theme-bg: hsla(var(--theme-bg-h), var(--theme-bg-s), var(--theme-bg-l), var(--theme-bg-a));
  --theme-bg-r: 17;
  --theme-bg-g: 17;
  --theme-bg-b: 17;
  --theme-bg-h: 0deg;
  --theme-bg-s: 0%;
  --theme-bg-l: 6.6666666667%;
  --theme-bg-a: 1;
  --button-bg: hsla(var(--button-bg-h), var(--button-bg-s), var(--button-bg-l), var(--button-bg-a));
  --button-bg-r: 91;
  --button-bg-g: 193;
  --button-bg-b: 225;
  --button-bg-h: 194.328358209deg;
  --button-bg-s: 69.0721649485%;
  --button-bg-l: 61.9607843137%;
  --button-bg-a: 1;
  --button-text: hsla(var(--button-text-h), var(--button-text-s), var(--button-text-l), var(--button-text-a));
  --button-text-r: 255;
  --button-text-g: 255;
  --button-text-b: 255;
  --button-text-h: 0deg;
  --button-text-s: 0%;
  --button-text-l: 100%;
  --button-text-a: 1;
  --theme-br: hsla(var(--theme-br-h), var(--theme-br-s), var(--theme-br-l), var(--theme-br-a));
  --theme-br-r: 91;
  --theme-br-g: 193;
  --theme-br-b: 225;
  --theme-br-h: 194.328358209deg;
  --theme-br-s: 69.0721649485%;
  --theme-br-l: 61.9607843137%;
  --theme-br-a: 1;
  --theme-light: 0;
  --theme-dark: 1;
  --theme-tx--light: rgba(calc((var(--theme-tx-r) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-r) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-g) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-g) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-b) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-b) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-a) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-a) * (1 - (0.58 + (0.18 * var(--theme-dark)))))));
}

.theme-brand {
  --theme-tx: hsla(var(--theme-tx-h), var(--theme-tx-s), var(--theme-tx-l), var(--theme-tx-a));
  --theme-tx-r: 255;
  --theme-tx-g: 255;
  --theme-tx-b: 255;
  --theme-tx-h: 0deg;
  --theme-tx-s: 0%;
  --theme-tx-l: 100%;
  --theme-tx-a: 1;
  --theme-bg: hsla(var(--theme-bg-h), var(--theme-bg-s), var(--theme-bg-l), var(--theme-bg-a));
  --theme-bg-r: 9;
  --theme-bg-g: 137;
  --theme-bg-b: 177;
  --theme-bg-h: 194.2857142857deg;
  --theme-bg-s: 90.3225806452%;
  --theme-bg-l: 36.4705882353%;
  --theme-bg-a: 1;
  --button-bg: hsla(var(--button-bg-h), var(--button-bg-s), var(--button-bg-l), var(--button-bg-a));
  --button-bg-r: 34;
  --button-bg-g: 29;
  --button-bg-b: 93;
  --button-bg-h: 244.6875deg;
  --button-bg-s: 52.4590163934%;
  --button-bg-l: 23.9215686275%;
  --button-bg-a: 1;
  --button-text: hsla(var(--button-text-h), var(--button-text-s), var(--button-text-l), var(--button-text-a));
  --button-text-r: 255;
  --button-text-g: 255;
  --button-text-b: 255;
  --button-text-h: 0deg;
  --button-text-s: 0%;
  --button-text-l: 100%;
  --button-text-a: 1;
  --theme-br: hsla(var(--theme-br-h), var(--theme-br-s), var(--theme-br-l), var(--theme-br-a));
  --theme-br-r: 34;
  --theme-br-g: 29;
  --theme-br-b: 93;
  --theme-br-h: 244.6875deg;
  --theme-br-s: 52.4590163934%;
  --theme-br-l: 23.9215686275%;
  --theme-br-a: 1;
  --theme-light: 0;
  --theme-dark: 1;
  --theme-tx--light: rgba(calc((var(--theme-tx-r) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-r) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-g) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-g) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-b) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-b) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-a) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-a) * (1 - (0.58 + (0.18 * var(--theme-dark)))))));
}

.theme-dark-blue {
  --theme-tx: hsla(var(--theme-tx-h), var(--theme-tx-s), var(--theme-tx-l), var(--theme-tx-a));
  --theme-tx-r: 255;
  --theme-tx-g: 255;
  --theme-tx-b: 255;
  --theme-tx-h: 0deg;
  --theme-tx-s: 0%;
  --theme-tx-l: 100%;
  --theme-tx-a: 1;
  --theme-bg: hsla(var(--theme-bg-h), var(--theme-bg-s), var(--theme-bg-l), var(--theme-bg-a));
  --theme-bg-r: 34;
  --theme-bg-g: 29;
  --theme-bg-b: 93;
  --theme-bg-h: 244.6875deg;
  --theme-bg-s: 52.4590163934%;
  --theme-bg-l: 23.9215686275%;
  --theme-bg-a: 1;
  --button-bg: hsla(var(--button-bg-h), var(--button-bg-s), var(--button-bg-l), var(--button-bg-a));
  --button-bg-r: 91;
  --button-bg-g: 193;
  --button-bg-b: 225;
  --button-bg-h: 194.328358209deg;
  --button-bg-s: 69.0721649485%;
  --button-bg-l: 61.9607843137%;
  --button-bg-a: 1;
  --button-text: hsla(var(--button-text-h), var(--button-text-s), var(--button-text-l), var(--button-text-a));
  --button-text-r: 255;
  --button-text-g: 255;
  --button-text-b: 255;
  --button-text-h: 0deg;
  --button-text-s: 0%;
  --button-text-l: 100%;
  --button-text-a: 1;
  --theme-br: hsla(var(--theme-br-h), var(--theme-br-s), var(--theme-br-l), var(--theme-br-a));
  --theme-br-r: 91;
  --theme-br-g: 193;
  --theme-br-b: 225;
  --theme-br-h: 194.328358209deg;
  --theme-br-s: 69.0721649485%;
  --theme-br-l: 61.9607843137%;
  --theme-br-a: 1;
  --theme-light: 0;
  --theme-dark: 1;
  --theme-tx--light: rgba(calc((var(--theme-tx-r) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-r) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-g) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-g) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-b) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-b) * (1 - (0.58 + (0.18 * var(--theme-dark)))))), calc((var(--theme-tx-a) * (0.58 + (0.18 * var(--theme-dark)))) + (var(--theme-bg-a) * (1 - (0.58 + (0.18 * var(--theme-dark)))))));
}

select {
  background-color: rgba(calc((var(--theme-bg-r) * 0.8) + (var(--theme-tx-r) * 0.2)), calc((var(--theme-bg-g) * 0.8) + (var(--theme-tx-g) * 0.2)), calc((var(--theme-bg-b) * 0.8) + (var(--theme-tx-b) * 0.2)), calc((var(--theme-bg-a) * 0.8) + (var(--theme-tx-a) * 0.2)));
}

:link,
:visited {
  color: var(--theme-link, var(--theme-tx, inherit));
  text-decoration: var(--theme-link-decoration, none);
}

.theme-links {
  --theme-link: var(--theme-br);
  --theme-link-decoration: underline;
}
.theme-links :visited, .theme-links:visited {
  --theme-link: hsla(var(--theme-br-h), calc(var(--theme-br-s) + ((var(--theme-br-s) - 0%) * -0.4)), calc((var(--theme-br-l) * 0.8) + (var(--theme-tx-l) * 0.2)), var(--theme-br-a));
}
.theme-links :link:hover, .theme-links:link:hover {
  --theme-link: hsla(var(--theme-br-h), calc(var(--theme-br-s) + ((100% - var(--theme-br-s)) * 0.5)), calc((var(--theme-br-l) * 0.5) + (var(--theme-tx-l) * 0.5)), var(--theme-br-a));
  --theme-link-decoration: underline;
}

.subtle-links {
  --theme-link: initial !important;
  --theme-link-decoration: initial !important;
}

.project-map__state {
  fill: var(--map-fill);
  stroke: var(--map-stroke);
  stroke-width: 3px;
  transform-origin: center;
  transform-box: view-box;
}
.project-map__state--highlighted {
  animation-name: map-highlight;
  animation-delay: calc(var(--scroll-animation-delay, 0s) + var(--highlight-order, 0) * 0.02s);
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-play-state: var(--scroll-animation-play-state, running);
}

.panel-tiles {
  stroke: var(--panel-tiles);
}

.theme-duotone {
  --fa-primary-color: var(--theme-tx);
  --fa-secondary-color: var(--theme-br);
  --fa-secondary-opacity: 1;
}

.theme-duotone-2 {
  --fa-primary-color: var(--theme-br);
  --fa-secondary-color: var(--theme-tx);
  --fa-secondary-opacity: 1;
}

.button {
  @apply px-2 py-1 rounded-md duration-300;
  display: inline-block;
  color: white;
  background-color: var(--theme-br);
}
.button:hover {
  background-color: var(--theme-br--light);
}

@supports ((-webkit-perspective: 1px) or (perspective: 1px)) and (not (-webkit-overflow-scrolling: touch)) {
  .parallax {
    transform: translateZ(0);
  }
}
.parallax::before {
  content: "";
  position: fixed;
  top: 0;
  width: 1px;
  height: 1px;
  z-index: 1;
}
.parallax__page {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
@supports ((-webkit-perspective: 1px) or (perspective: 1px)) and (not (-webkit-overflow-scrolling: touch)) {
  .parallax__page {
    height: 100vh;
    perspective-origin: left;
    --parallax-perspective: 100px;
    perspective: var(--parallax-perspective);
  }
  .parallax__page > * {
    transform: translateZ(0);
    transform-style: preserve-3d;
  }
}
.parallax__group {
  position: relative;
  pointer-events: none;
  background-color: transparent;
  z-index: -1;
}
.parallax__group > * {
  position: relative;
  pointer-events: auto;
}
@supports ((-webkit-perspective: 1px) or (perspective: 1px)) and (not (-webkit-overflow-scrolling: touch)) {
  .parallax__group {
    transform-style: preserve-3d;
  }
}
.parallax__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
@supports ((-webkit-perspective: 1px) or (perspective: 1px)) and (not (-webkit-overflow-scrolling: touch)) {
  .parallax__bg {
    transform-origin: left;
    --parallax-depth: 0;
    --parallax-scale: calc(1 + max(var(--parallax-depth), var(--parallax-depth) * -1));
    --parallax-height-adjust: calc((1 - 1 / var(--parallax-scale)) * (50% - 50vh));
    top: var(--parallax-height-adjust);
    bottom: var(--parallax-height-adjust);
    transform: translateZ(calc(var(--parallax-depth) * var(--parallax-perspective))) scale(var(--parallax-scale));
  }
}
.parallax__bg--medium {
  --parallax-depth: -0.32;
}
.parallax__bg--shallow {
  --parallax-depth: -0.16;
}

.p-container {
  @apply px-4 sm:px-8;
}

.pseudo-fill-parent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.hover-borders {
  position: relative;
}
.hover-borders::after {
  content: "";
  @apply absolute z-10 border-t-42blue border-r-42blue border-l-42green border-b-42green;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  bottom: -1rem;
  border-width: 0.5rem;
  border-radius: inherit;
  opacity: 0;
  clip-path: polygon(0 100%, 0 100%, 0.5rem calc(100% - 0.5rem), calc(100% - 0.5rem) 0.5rem, 100% 0, 100% 0, 100% 0, calc(100% - 0.5rem) 0.5rem, 0.5rem calc(100% - 0.5rem), 0 100%);
  transition-property: clip-path, opacity;
  transform: translateZ(0);
}
.hover-borders::after {
  transition-duration: inherit;
}
.hover-borders:hover::after {
  clip-path: polygon(0 100%, 0 2rem, 0.5rem 1.5rem, 1.5rem 0.5rem, 2rem 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 0.5rem) calc(100% - 1.5rem), calc(100% - 1.5rem) calc(100% - 0.5rem), calc(100% - 2rem) 100%);
  opacity: 1;
}

.translate-article-title {
  transform: translateY(calc(-100% - theme("spacing.8")));
}

.preview-link {
  --text-color: var(--theme-tx);
  color: var(--text-color);
  @apply border-gray-200 border-t last:border-b;
}
.preview-link__title {
  --text-color: var(--theme-br);
}
.preview-link, .preview-link__title {
  color: var(--text-color);
  @apply duration-150;
}
.preview-link--active, .preview-link__title--active {
  --text-color: white;
}
.preview-link--active {
  @apply bg-42blue-dark;
}
.preview-link--active, .preview-link--active + .preview-link {
  @apply border-t-42blue-dark;
}
.preview-link:hover, .preview-link:hover .preview-link__title {
  --text-color: white;
}
.preview-link:hover {
  @apply bg-42blue border-42blue;
}
.preview-link:hover, .preview-link:hover + .preview-link {
  @apply border-t-42blue;
}

.header {
  --header-height: 64px;
  --background-color: var(--theme-bg);
  --text-color: var(--theme-tx);
  --transition: 0.3s;
  position: relative;
  width: 100%;
  text-align: left;
  height: var(--header-height);
  color: inherit;
  color: var(--text-color, inherit);
  background-color: inherit;
  background-color: var(--background-color, inherit);
}
.header, .header * {
  box-sizing: border-box;
}
.header__nav {
  width: 100%;
  height: 100%;
  background-color: inherit;
}
.header__mobile {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  height: 100%;
}
.header__logo {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  margin-right: auto;
  height: 100%;
}
.header__logo svg, .header__logo-img {
  display: block;
  width: auto;
  height: 100%;
}
.header__mobile-buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  margin-left: auto;
  height: 100%;
}
.header__mobile-button {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}
.header__mobile-button svg {
  height: 1em;
}
.header__nav--target:target .header__mobile-button--open {
  display: none;
}
.header__nav--target:not(:target) .header__mobile-button--close {
  display: none;
}
.header__links {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 0;
  background-color: inherit;
  background-color: var(--background-color, inherit);
  overflow: hidden;
  transition-duration: var(--transition);
  transition-property: max-height;
  transition-timing-function: ease-out;
}
.header__nav--target:target .header__links {
  max-height: 100vw;
}
.header__link-container {
  position: relative;
  height: 100%;
}
.header__dropdown {
  min-width: 100%;
  background-color: inherit;
  background-color: var(--background-color, inherit);
}
.header__link {
  display: block;
  height: auto;
  background-color: inherit;
  background-color: var(--background-color, inherit);
  white-space: nowrap;
}
@media screen and (min-width: 520px) {
  .header__nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  .header__mobile-buttons {
    display: none;
  }
  .header__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
  }
  .header__link-container:not(:hover) .header__dropdown {
    display: none;
  }
  .header__links {
    position: static;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin-left: auto;
    width: auto;
    height: 100%;
    max-height: none;
    overflow: visible;
  }
  .header__links > * {
    width: auto;
    margin-bottom: 0;
  }
  .header__link {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
  .header__link--sub-link {
    height: var(--header-height);
  }
}
.header__nav {
  @apply container mx-auto;
}
.header__links {
  @apply items-center;
}
.header__link {
  @apply px-4 py-2 z-10;
  position: relative;
  background-color: transparent;
}
@media (min-width: 520px) {
  .header__link {
    height: auto !important;
    position: relative;
  }
  .header__link::before {
    content: "";
    position: absolute;
    height: var(--header-height);
    top: calc(50% - var(--header-height) / 2);
    left: 0;
    right: 0;
  }
  .header__link::after {
    content: "";
    @apply absolute z-10 border-t-42blue border-r-42blue border-l-42green border-b-42green;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-width: 4px;
    border-radius: inherit;
    opacity: 0;
    clip-path: polygon(0 100%, 0 100%, 4px calc(100% - 4px), calc(100% - 4px) 4px, 100% 0, 100% 0, 100% 0, calc(100% - 4px) 4px, 4px calc(100% - 4px), 0 100%);
    transition-property: clip-path, opacity;
    transform: translateZ(0);
  }
  .header__link::after {
    transition-duration: 0.3s, 0.15s;
    transition-delay: 0s, 0.15s;
  }
  .header__link:hover::after {
    transition-delay: 0s;
  }
  .header__link:hover::after {
    clip-path: polygon(0 100%, 0 16px, 4px 12px, 12px 4px, 16px 0, 100% 0, 100% calc(100% - 16px), calc(100% - 4px) calc(100% - 12px), calc(100% - 12px) calc(100% - 4px), calc(100% - 16px) 100%);
    opacity: 1;
  }
}
@media (max-width: 519px) {
  .header__link {
    @apply pl-8 border-b-2 border-slate-100;
  }
  .header__link:first-child {
    @apply border-t-2;
  }
}
.header__logo {
  @apply -ml-4;
  padding: 1px 16px;
}

.splash {
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (orientation: landscape) and (min-width: 1921px) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1920px) and (min-width: 1681px) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1680px) and (min-width: 1441px) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1800.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1800.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1800.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1800.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-3072.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-3072.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-3072.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (min-resolution: 145dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-3072.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-3072.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-3072.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (min-resolution: 289dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-3072.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-3072.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-3072.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2415.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2415.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2415.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-824.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2415.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2415.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2415.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-720.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-720.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-720.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (min-resolution: 289dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2732.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2732.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-640.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-640.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-640.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-640.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (min-resolution: 289dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-720.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-720.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-720.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-480.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-480.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-480.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-2048.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-2048.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-2048.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (min-resolution: 145dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1800.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1800.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1800.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-640.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-640.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-640.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (min-resolution: 289dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1800.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1800.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1800.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-480.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-480.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-480.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-824.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-824.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-412.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-412.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-412.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-412.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-412.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (min-resolution: 289dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1600.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1600.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1200.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1200.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-720.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-720.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-720.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-720.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-540.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-540.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-540.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-540.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-540.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-360.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-360.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-360.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-360.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-360.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (min-resolution: 289dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1380.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1380.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-1024.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-1024.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-640.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-640.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-640.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-480.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-480.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-480.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 96dpi) {
  .splash {
    background-image: image-set(url("/assets/generated/Y4acobjy_8-320.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-320.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/Y4acobjy_8-320.webp") type("image/webp"), url("/assets/generated/Y4acobjy_8-320.jpeg") type("image/jpeg"))) {
    .splash {
      background-image: url("/assets/generated/Y4acobjy_8-320.jpeg");
    }
  }
}

.splash-news {
  background-position: center 88%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (orientation: landscape) and (min-width: 1921px) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1920px) and (min-width: 1681px) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1680px) and (min-width: 1441px) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1800.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1800.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1800.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1800.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-3072.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-3072.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-3072.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (min-resolution: 145dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-3072.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-3072.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-3072.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (min-resolution: 289dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-3072.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-3072.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-3072.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2415.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2415.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2415.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-824.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2415.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2415.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2415.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-720.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-720.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-720.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (min-resolution: 289dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2732.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2732.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-640.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-640.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-640.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-640.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (min-resolution: 289dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-720.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-720.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-720.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-480.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-480.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-480.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-2048.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-2048.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-2048.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (min-resolution: 145dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1800.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1800.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1800.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-640.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-640.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-640.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (min-resolution: 289dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1800.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1800.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1800.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-480.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-480.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-480.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-824.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-824.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-412.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-412.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-412.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-412.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-412.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (min-resolution: 289dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1600.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1600.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1200.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1200.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-720.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-720.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-720.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-720.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-540.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-540.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-540.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-540.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-540.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-360.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-360.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-360.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-360.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-360.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (min-resolution: 289dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1380.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1380.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-1024.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-1024.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-640.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-640.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-640.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-480.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-480.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-480.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 96dpi) {
  .splash-news {
    background-image: image-set(url("/assets/generated/tWMY1LHzU5-320.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-320.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/tWMY1LHzU5-320.webp") type("image/webp"), url("/assets/generated/tWMY1LHzU5-320.jpeg") type("image/jpeg"))) {
    .splash-news {
      background-image: url("/assets/generated/tWMY1LHzU5-320.jpeg");
    }
  }
}

.splash-careers {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (orientation: landscape) and (min-width: 1921px) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1920px) and (min-width: 1681px) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1680px) and (min-width: 1441px) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1800.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1800.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1800.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1800.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-3072.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-3072.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-3072.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (min-resolution: 145dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-3072.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-3072.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-3072.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (min-resolution: 289dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-3072.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-3072.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-3072.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2415.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2415.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2415.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-824.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2415.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2415.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2415.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-720.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-720.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-720.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (min-resolution: 289dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2732.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2732.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-640.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-640.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-640.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-640.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (min-resolution: 289dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-720.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-720.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-720.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-480.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-480.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-480.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-2048.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-2048.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-2048.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (min-resolution: 145dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1800.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1800.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1800.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-640.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-640.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-640.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (min-resolution: 289dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1800.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1800.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1800.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-480.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-480.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-480.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-824.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-824.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-412.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-412.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-412.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-412.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-412.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (min-resolution: 289dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1600.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1600.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1200.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1200.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-720.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-720.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-720.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-720.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-540.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-540.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-540.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-540.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-540.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-360.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-360.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-360.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-360.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-360.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (min-resolution: 289dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1380.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1380.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-1024.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-1024.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-640.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-640.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-640.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-480.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-480.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-480.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 96dpi) {
  .splash-careers {
    background-image: image-set(url("/assets/generated/2p4Wq7Czrj-320.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-320.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/2p4Wq7Czrj-320.webp") type("image/webp"), url("/assets/generated/2p4Wq7Czrj-320.jpeg") type("image/jpeg"))) {
    .splash-careers {
      background-image: url("/assets/generated/2p4Wq7Czrj-320.jpeg");
    }
  }
}

.splash-team {
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (orientation: landscape) and (min-width: 1921px) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1920px) and (min-width: 1681px) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1680px) and (min-width: 1441px) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1800.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1800.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1800.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1800.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-3072.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-3072.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-3072.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (min-resolution: 145dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-3072.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-3072.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-3072.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (min-resolution: 289dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-3072.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-3072.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-3072.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2415.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2415.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2415.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-824.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2415.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2415.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2415.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-720.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-720.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-720.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (min-resolution: 289dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2732.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2732.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-640.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-640.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-640.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-640.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (min-resolution: 289dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-720.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-720.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-720.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-480.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-480.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-480.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-2048.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-2048.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-2048.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (min-resolution: 145dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1800.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1800.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1800.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-640.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-640.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-640.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (min-resolution: 289dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1800.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1800.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1800.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-480.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-480.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-480.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-824.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-824.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-412.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-412.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-412.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-412.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-412.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (min-resolution: 289dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1600.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1600.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1200.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1200.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-720.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-720.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-720.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-720.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-540.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-540.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-540.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-540.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-540.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-360.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-360.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-360.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-360.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-360.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (min-resolution: 289dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1380.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1380.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-1024.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-1024.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-640.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-640.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-640.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-480.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-480.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-480.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 96dpi) {
  .splash-team {
    background-image: image-set(url("/assets/generated/G78LWu3PH1-320.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-320.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: image-set(url("/assets/generated/G78LWu3PH1-320.webp") type("image/webp"), url("/assets/generated/G78LWu3PH1-320.jpeg") type("image/jpeg"))) {
    .splash-team {
      background-image: url("/assets/generated/G78LWu3PH1-320.jpeg");
    }
  }
}

.careers-footer {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (orientation: landscape) and (min-width: 1921px) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1920px) and (min-width: 1681px) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1680px) and (min-width: 1441px) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1800.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1800.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1800.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1800.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-3072.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-3072.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-3072.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1440px) and (min-width: 1367px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1366px) and (min-width: 1281px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (min-resolution: 145dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1280px) and (min-width: 1025px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 1024px) and (min-width: 961px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-3072.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-3072.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-3072.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 960px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (min-resolution: 289dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-3072.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-3072.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-3072.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-3072.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-3072.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2415.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2415.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2415.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 768px) and (min-width: 691px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-824.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2415.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2415.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2415.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2415.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2415.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 690px) and (min-width: 641px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-720.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-720.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-720.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (min-resolution: 289dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2732.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2732.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2732.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1380.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 640px) and (min-width: 481px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-640.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-640.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-640.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-640.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (min-resolution: 289dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1600.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1024.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-720.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-720.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-720.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-720.jpeg");
    }
  }
}
@media (orientation: landscape) and (max-width: 480px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-480.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-480.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-480.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-2048.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-2048.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-2048.jpeg");
    }
  }
}
@media (orientation: portrait) and (min-width: 801px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (min-resolution: 145dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 800px) and (min-width: 769px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 768px) and (min-width: 601px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1800.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1800.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1800.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 600px) and (min-width: 433px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-640.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-640.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-640.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (min-resolution: 289dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1800.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1800.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1800.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1800.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1800.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 288dpi) and (min-resolution: 241dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 240dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 432px) and (min-width: 413px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-480.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-480.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-480.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 192dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-824.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-824.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-824.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 412px) and (min-width: 361px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-412.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-412.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-412.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-412.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-412.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (min-resolution: 289dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1600.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1600.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1600.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1200.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1200.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1200.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-720.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-720.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-720.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-720.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-720.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-540.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-540.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-540.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-540.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-540.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 360px) and (min-width: 321px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-360.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-360.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-360.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-360.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-360.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (min-resolution: 289dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1380.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1380.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1380.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 288dpi) and (min-resolution: 193dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-1024.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-1024.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-1024.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 192dpi) and (min-resolution: 145dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-640.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-640.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-640.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-640.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-640.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 144dpi) and (min-resolution: 97dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-480.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-480.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-480.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-480.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-480.jpeg");
    }
  }
}
@media (orientation: portrait) and (max-width: 320px) and (max-resolution: 96dpi) {
  .careers-footer {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-320.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-320.jpeg") type("image/jpeg"));
  }
  @supports not (background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), image-set(url("/assets/generated/3M5dGOBbjF-320.webp") type("image/webp"), url("/assets/generated/3M5dGOBbjF-320.jpeg") type("image/jpeg"))) {
    .careers-footer {
      background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.6)), url("/assets/generated/3M5dGOBbjF-320.jpeg");
    }
  }
}

.page-title__text, .page-title__borders::before, .page-title__borders::after, .page-title::before, .page-title::after {
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.24, 1.03, 0.81, 0.99);
  animation-duration: 0.7s;
  animation-delay: calc(var(--animation-load-delay) + var(--animation-delay));
}

.page-title {
  --animation-delay: 1s;
  @apply relative z-10;
}
.page-title::before, .page-title::after {
  content: "";
  @apply absolute w-full h-full -z-10;
  mix-blend-mode: multiply;
}
.page-title::before {
  @apply -top-1.5 -right-1.5 bg-42blue;
  animation-name: fly-in-r;
}
.page-title::after {
  @apply -bottom-1 -left-1.5 bg-42green;
  animation-name: fly-in-l;
}
.page-title__borders::before, .page-title__borders::after {
  content: "";
  @apply absolute w-full h-full border-white z-0;
  animation-delay: calc(var(--animation-load-delay) + calc(var(--animation-delay) + 0.1s));
}
.page-title__borders::before {
  @apply left-3 -top-1 border-l-2;
  animation-name: fly-in-t;
}
.page-title__borders::after {
  @apply bottom-1 right-0 border-b-2;
  animation-name: fly-in-l;
}
.page-title__text {
  @apply px-5 py-2.5;
  animation-name: fade-in, slide-r;
  animation-delay: calc(var(--animation-load-delay) + calc(var(--animation-delay) + 0.1s));
}

.vignette {
  box-shadow: 0 0 8em 0 rgba(34, 29, 93, 0.7) inset;
}

.vector-panel {
  --fill: transparent;
  --stroke-fallback: hsla(var(--theme-bg-h), var(--theme-bg-s), calc(var(--theme-bg-l) + 12%), var(--theme-bg-a));
  --stroke: var(--theme-br, var(--stroke-fallback, white));
  stroke: var(--stroke);
  stroke-width: 0.4px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  opacity: 0.2;
}
.vector-panel__background {
  fill: var(--fill);
}
.vector-panel__corner {
  fill: var(--stroke);
}
.vector-panel__line {
  stroke-width: 0.1px;
}
.vector-panel__line--middle {
  stroke-width: 0.2px;
}

.content-shadow {
  --spread: clamp(1rem, 10vw, 4rem);
  --color: hsla(var(--theme-bg-h), var(--theme-bg-s), var(--theme-bg-l), 0.5);
  background-color: var(--color);
  box-shadow: 0 0 var(--spread) calc(2rem + var(--spread)) var(--color);
}

.team {
  --columns: 1;
  --bio-duration: 0.5s;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  justify-items: center;
  gap: 1.5rem 2rem;
}

.team__member {
  display: contents;
}

.team__member-preview {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.team__headshot {
  --headshot-size: 7rem;
  width: var(--headshot-size);
  height: var(--headshot-size);
  line-height: var(--headshot-size);
  aspect-ratio: 1/1;
  user-select: none;
}

.team__headshot-fallback {
  font-size: 3rem;
  width: 100%;
  height: 100%;
}

.team__member-info {
  margin-left: 2rem;
}

.team__bio {
  position: relative;
  width: 100vw;
  height: 0;
  overflow: hidden;
  grid-row-start: var(--bio-row);
  grid-row-end: var(--bio-row);
  grid-column: 1/-1;
  background-color: transparent;
  transition-property: height;
  transition-duration: var(--bio-duration);
  transition-timing-function: ease-out;
}

.team__bio-content {
  opacity: 0;
  transform: translateX(1rem);
}
.team__bio-content--active, .team__bio-content--left, .team__bio-content--right {
  transition-property: opacity, transform;
  transition-duration: var(--bio-duration);
  transition-timing-function: inherit;
}
.team__bio-content--active {
  opacity: 1;
  transform: translateX(0);
  transition-delay: calc(var(--bio-duration) / 2);
}
.team__bio-content--left {
  opacity: 0;
  transform: translateX(-1rem);
}
.team__bio-content--right {
  opacity: 0;
  transform: translateX(1rem);
}

.team__member:target .team__bio {
  height: auto;
}
.team__member:target .team__bio-content {
  opacity: 1;
}

.team {
  @apply mx-auto mt-16;
  max-width: 24rem;
}

.team__member-preview {
  @apply pl-4 sm:pl-0;
}

.team__headshot {
  border-radius: 100%;
  transition-duration: var(--bio-duration);
  position: relative;
}
.team__headshot::after {
  content: "";
  @apply absolute z-10 border-t-42blue border-r-42blue border-l-42green border-b-42green;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  bottom: -1rem;
  border-width: 0.5rem;
  border-radius: inherit;
  opacity: 0;
  clip-path: polygon(0 100%, 0 100%, 0.5rem calc(100% - 0.5rem), calc(100% - 0.5rem) 0.5rem, 100% 0, 100% 0, 100% 0, calc(100% - 0.5rem) 0.5rem, 0.5rem calc(100% - 0.5rem), 0 100%);
  transition-property: clip-path, opacity;
  transform: translateZ(0);
}
.team__headshot::after {
  transition-duration: inherit;
}
@media (max-width: 24rem) {
  .team__headshot {
    --headshot-size: 6rem;
  }
}

.team__member-preview:hover .team__headshot::after, .team__member:target .team__headshot::after, .team__headshot--active::after {
  clip-path: polygon(0 100%, 0 50%, 0.5rem calc(50% - 0.5rem), calc(50% - 0.5rem) 0.5rem, 50% 0, 100% 0, 100% calc(100% - 50%), calc(100% - 0.5rem) calc(100% - calc(50% - 0.5rem)), calc(100% - calc(50% - 0.5rem)) calc(100% - 0.5rem), calc(100% - 50%) 100%);
  opacity: 1;
}

.team__headshot-img {
  border-radius: 100%;
  object-fit: cover;
  transition-duration: var(--bio-duration);
}

.team__headshot-fallback {
  @apply bg-sky-50 text-black/75 font-serif;
  border-radius: inherit;
  overflow: hidden;
  text-align: center;
  text-transform: capitalize;
}

.team__button {
  @apply pseudo-fill-parent;
}

.team__member-name {
  @apply text-lg font-semibold text-theme-br;
}

.team__bio {
  @apply before:bg-theme-br after:bg-sky-50 font-serif;
}
.team__bio::before {
  content: "";
  position: absolute;
  width: 0.5rem;
  top: 2rem;
  bottom: 2rem;
  left: calc(50% - 33ch - 2rem);
  transform: skewY(-45deg) translateZ(0);
}
.team__bio::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.team__bio-content {
  @apply box-content max-w-prose mx-auto px-4 my-8 space-y-4 leading-relaxed;
}

@media (min-width: 640px) {
  .team {
    max-width: none;
  }
  .team__member-preview {
    flex-direction: column;
    max-width: 16rem;
    text-align: center;
  }
  .team__headshot {
    --headshot-size: 9rem;
    margin-left: auto;
    margin-right: auto;
  }
  .team__member-info {
    margin-left: 0;
    margin-top: 1rem;
  }
  .team {
    --columns: 2;
  }
  .team__member:nth-child(n+1) {
    --bio-row: 2;
  }
  .team__headshot-fallback {
    font-size: 3.5rem;
  }
}
@media (min-width: 768px) {
  .team {
    --columns: 3;
  }
  .team__member:nth-child(n+1) {
    --bio-row: 2;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;